// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-reader {
    width: 430px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
  }
  
  .qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
  }
  
  .qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  /* Media Queries for mobile screens */
  @media (max-width: 426px) {
    .qr-reader {
      width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/asignarxQR/qrStyles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,QAAQ;IACR,4CAA4C;EAC9C;;EAEA,qCAAqC;EACrC;IACE;MACE,WAAW;IACb;EACF","sourcesContent":[".qr-reader {\n    width: 430px;\n    height: 100vh;\n    margin: 0 auto;\n    position: relative;\n  }\n  \n  .qr-reader video {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  \n  .qr-reader .qr-box {\n    width: 100% !important;\n    left: 0 !important;\n  }\n  \n  .qr-reader .qr-frame {\n    position: absolute;\n    fill: none;\n    left: 50%;\n    top: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  }\n  \n  /* Media Queries for mobile screens */\n  @media (max-width: 426px) {\n    .qr-reader {\n      width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
