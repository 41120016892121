// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.login-left {
  width: 50%;
  padding: 20px;
  background: #e0f7fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-right {
  width: 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo,
.logo-right {
  width: 150px;
  margin-bottom: 20px;
}

.illustration {
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}

h2 {
  color: #333;
  margin-bottom: 10px;
}

p {
  color: #666;
  margin-bottom: 20px;
}

.textbox {
  width: 100%;
  margin-bottom: 20px;
}

.textbox input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn {
  width: 100%;
  padding: 10px;
  background: #0288d1;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.btn:hover {
  background: #0277bd;
}
`, "",{"version":3,"sources":["webpack://./src/components/login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,uCAAuC;EACvC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".login-container {\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  background: #fff;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  overflow: hidden;\n}\n\n.login-left {\n  width: 50%;\n  padding: 20px;\n  background: #e0f7fa;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.login-right {\n  width: 50%;\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.logo,\n.logo-right {\n  width: 150px;\n  margin-bottom: 20px;\n}\n\n.illustration {\n  width: 100%;\n  max-width: 500px;\n  margin-top: 20px;\n}\n\nh2 {\n  color: #333;\n  margin-bottom: 10px;\n}\n\np {\n  color: #666;\n  margin-bottom: 20px;\n}\n\n.textbox {\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.textbox input {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  box-sizing: border-box;\n}\n\n.btn {\n  width: 100%;\n  padding: 10px;\n  background: #0288d1;\n  border: none;\n  border-radius: 4px;\n  color: #fff;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.btn:hover {\n  background: #0277bd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
